import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VCardTitle,[_vm._v(" Qualidade de Venda - Regional ")]),_c(VDivider,{staticClass:"mt-0"}),_c(VCardText,{staticClass:"d-flex align-center flex-wrap pb-0"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"4","md":"3","lg":"3"}},[_c(VAutocomplete,{staticClass:"user-search mb-4",attrs:{"items":_vm.regionList,"loading":_vm.loadingRegionList,"label":"Região ","outlined":"","disabled":!_vm.regionList.length,"hide-details":"","dense":""},on:{"input":_vm.onInput},model:{value:(_vm.filterOptions.region),callback:function ($$v) {_vm.$set(_vm.filterOptions, "region", $$v)},expression:"filterOptions.region"}})],1)],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.saleQualityList,"loading":_vm.loading,"loading-text":"Carregando dados...","page":_vm.pageOptions.page,"items-per-page":_vm.pageOptions.itemsPerPage,"fixed-header":"","disable-sort":""},on:{"update:page":function($event){return _vm.$set(_vm.pageOptions, "page", $event)},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('span',{class:_vm.classNoDataText},[_vm._v(_vm._s(_vm.noDataText))])]},proxy:true}])}),_c(VDivider,{staticClass:"mt-4"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }