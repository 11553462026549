<template>
  <div>
    <v-card>
      <v-card-title>
        Qualidade de Venda - Regional
      </v-card-title>

      <v-divider class="mt-0"></v-divider>

      <!-- filter quicksearch -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-row>
          <v-col
            cols="12"
            sm="4"
            md="3"
            lg="3"
          >
            <v-autocomplete
              v-model="filterOptions.region"
              :items="regionList"
              :loading="loadingRegionList"
              label="Região "
              outlined
              :disabled="!regionList.length"
              hide-details
              dense
              class="user-search mb-4"
              @input="onInput"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>

      <!--table-->
      <v-data-table
        :headers="headers"
        :items="saleQualityList"
        :loading="loading"
        loading-text="Carregando dados..."
        :page.sync="pageOptions.page"
        :items-per-page="pageOptions.itemsPerPage"
        fixed-header
        disable-sort
        @page-count="pageCount = $event"
      >
        <!--data empty-->
        <template
          v-slot:no-data
        >
          <span :class="classNoDataText">{{ noDataText }}</span>
        </template>
      </v-data-table>

      <v-divider class="mt-4"></v-divider>

      <!-- pagination -->
      <!-- <v-card-text class="pt-2 pb-1">
        <v-row>
          <v-col
            lg="12"
            cols="12"
            class="d-flex justify-center"
          >
            <v-pagination
              v-model="pageOptions.page"
              total-visible="6"
              :length="pageOptions.pageCount"
              @input="updatePage"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text> -->
    </v-card>
  </div>
</template>
<script>
import axiosIns from '@/plugins/axios'
import {
  mdiAccountOutline,
  mdiAutorenew,
  mdiCalendar,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiEyeOutline,
  mdiFileDocumentOutline,
  mdiFilter,
  mdiFilterMenu,
  mdiFilterRemove,
  mdiLoading,
  mdiMagnify,
  mdiPencilOutline,
  mdiPlus,
  mdiSquareEditOutline,
  mdiTextBoxSearch,
  mdiWhatsapp,
} from '@mdi/js'

export default {
  components: {
    // LgpdModal,
  },

  data: () => ({
    icons: {
      mdiSquareEditOutline,
      mdiDotsVertical,
      mdiPencilOutline,
      mdiFileDocumentOutline,
      mdiDeleteOutline,
      mdiPlus,
      mdiExportVariant,
      mdiAccountOutline,
      mdiFilter,
      mdiFilterRemove,
      mdiWhatsapp,
      mdiCalendar,
      mdiFilterMenu,
      mdiLoading,
      mdiEyeOutline,
      mdiMagnify,
      mdiTextBoxSearch,
      mdiAutorenew,
    },

    /* filter */
    filterOptions: {
      region: '',
    },

    /* data table */
    saleQualityList: [],
    regionList: [],
    noDataText: '',
    classNoDataText: '',

    // heightDinamic: '400px',
    loading: false,
    loadingRegionList: false,
    headers: [
      { text: 'REFERÊNCIA', value: 'reference' },
      { text: 'CIDADE', value: 'fantasy_name' },
      { text: 'VENDA', value: 'venda' },
      { text: 'NORMAL', value: 'normal' },
      { text: '% NORMAL', value: 'indicenormal' },
      { text: 'JURÍDICO', value: 'juridico' },
      { text: 'PRÉ-CONTEMPLA', value: 'precontemplado' },
      { text: 'CONTEMPLADO', value: 'contemplado' },
      { text: '% CONTEMPLA', value: 'indicecontemplacao' },
      { text: 'PRÉ-CANCELADO', value: 'precancelado' },
      { text: 'CANCELADO', value: 'cancelado' },
      { text: '% CANCELADO', value: 'indicecancela' },
    ],

    /* router */
    routeOptions: {
      resource: 'sales-quality',
      method: 'post',
      params: {},
    },

    /* pagination */
    pageOptions: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
    },
  }),

  watch: {
    //
  },

  created() {
    this.initialize()
  },

  methods: {
    initialize() {
      this.loadingRegionList = true
      this.noDataText = 'PESQUISE UMA REGIONAL PARA EXIBIR OS DADOS'
      this.classNoDataText = 'colorWarning'
      this.getRegionList()
    },

    /* data table methods */
    async onInput() {
      await this.getSalesQuality()
    },

    async getRegionList() {
      await axiosIns.get('/api/v1/records/region/index').then(res => {
        this.regionList = res.data.data.map(item => item.region)
        this.loadingRegionList = false
      })
    },

    async getSalesQuality() {
      this.loading = true
      this.noDataText = 'Nenhum registro encontrado'
      this.classNoDataText = ''
      this.saleQualityList = []
      this.routeOptions.params = {
        region_name: this.filterOptions.region,
      }
      await axiosIns
        .post('/api/v1/integration/contract/quality_sale_regions', this.routeOptions.params)
        .then(res => {
          // this.heightDinamic = `${window.screen.height * 0.45}px`
          res.data.data.map(value => {
            this.saleQualityList.push({
              reference: value.reference,
              fantasy_name: value.fantasy_name,
              venda: value.venda,
              normal: value.normal,
              juridico: value.juridico,
              cancelado: value.cancelado,
              precontemplado: value.precontemplado,
              contemplado: value.contemplado,
              precancelado: value.precancelado,
              indicecontemplacao: value.indicecontemplacao,
              indicecancela: value.indicecancela,
              indicenormal: value.indicenormal,
            })
          })

          // this.pageOptions.page = res.data.meta.current_page
          // this.pageOptions.itemsPerPage = res.data.meta.per_page
          // this.pageOptions.pageCount = res.data.meta.last_page
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          this.noDataText = `Contate o suporte do sistema: ${err}`
        })
    },

    /* pagination methods */
    // async updatePage() {
    //   this.loading = true
    //   this.saleQualityList = []
    //   await axiosIns
    //     .post(
    //       `/api/v1/integration/${this.routeOptions.resource}?page=${this.pageOptions.page}`,
    //       this.routeOptions.params,
    //     )
    //     .then(res => {
    //       res.data.data.map(value => {
    //         this.saleQualityList.push({
    //           nomevendedor: value.nomevendedor,
    //           venda: value.venda,
    //           normal: value.normal,
    //           juridico: value.juridico,
    //           cancelado: value.cancelado,
    //           precontemplado: value.precontemplado,
    //           contemplado: value.contemplado,
    //           precancelado: value.precancelado,
    //           indicecontemplacao: value.indicecontemplacao,
    //           indicecancela: value.indicecancela,
    //           indicenormal: value.indicenormal,
    //         })
    //       })
    //       this.pageOptions.itemsPerPage = res.data.meta.per_page
    //       this.pageOptions.pageCount = res.data.meta.last_page
    //       this.loading = false
    //     })
    //     .catch(err => {
    //       this.loading = false
    //       this.noDataText = `Contate o suporte do sistema: ${err}`
    //     })
    // },
  },
}
</script>
<style>
.colorWarning {
  color: #ff9800 !important;
}
</style>
